import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Button,
  Select,
  MenuItem,
  Rating,
  Skeleton,
  Autocomplete,
  TextField,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import callApi from "../../services/callApi";
import MenuProfesiones from "../../components/MenuCliente/MenuSinServicios";
import MenuClientea from "../../components/MenuCliente/MenuCliente";
import { isMobile } from "react-device-detect";
import { Link } from "@material-ui/core";
import LoadingSkeleton from "./Skeleton";

const SearchResults = () => {
  const location = useLocation();
  const [searchResponse, setSearchResponse] = useState(null);
  const [modalidad, setModalidad] = useState("presencial");
  const [especialidad, setEspecialidad] = useState("");
  const [comuna, setComuna] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchOptions, setSearchOptions] = useState({
    especialidades: [],
    comunas: [],
    modalidades: [], // Agregado modalidades
  });

  const [inputProfession, setInputProfession] = useState("");
  const [inputComuna, setInputComuna] = useState("");
  const [inputModalidad, setInputModalidad] = useState("");
  const modalityMapping = {
    "Consulta online": "remoto",
    "Consulta presencial": "consulta", // Cambiado de "consulta" a "presencial" para consistencia
    "Consulta a domicilio": "domicilio", // Corregido para coincidir con el texto de la API
  };

  const isSearchEnabled = () => {
    if (!especialidad) return false; // Primero validar que haya especialidad
    if (modalidad === "remoto" || modalidad === "online") return true;
    return modalidad && comuna; // Para presencial/domicilio requiere modalidad y comuna
  };

  useEffect(() => {
    const fetchSearchOptions = async () => {
      try {
        const response = await callApi("/pacientes/buscadorOpciones");
        setSearchOptions({
          especialidades: response.especialidades,
          comunas: response.comunas,
          modalidades: response.modalidades, // Agregado modalidades
        });
      } catch (error) {
        console.error("Error al obtener opciones de búsqueda:", error);
      }
    };

    fetchSearchOptions();
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      setIsLoading(true);
      try {
        let params;

        // Primero verificamos si hay state en la location
        if (location.state) {
          params = {
            modalidad: location.state.modalidad || "presencial",
            especialidad: location.state.especialidad || "",
            comuna: location.state.comuna || "",
          };
        } else {
          // Si no hay state, usamos los parámetros de la URL
          const searchParams = new URLSearchParams(location.search);
          params = {
            modalidad: searchParams.get("m") || "presencial",
            especialidad: searchParams.get("e") || "",
            comuna: searchParams.get("c") || "",
          };
        }

        console.log("Parámetros de búsqueda:", params);

        // Actualizar estados
        setModalidad(params.modalidad);
        setEspecialidad(params.especialidad);
        setComuna(params.comuna);

        // Construir URL para la API
        const apiUrl = `/pacientes/buscarBusqueda?m=${encodeURIComponent(
          params.modalidad
        )}&e=${encodeURIComponent(params.especialidad)}&c=${encodeURIComponent(
          params.comuna
        )}`;

        const response = await callApi(apiUrl);
        setSearchResponse(response);
      } catch (error) {
        console.error("Error al realizar la búsqueda:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSearchResults();
  }, [location]); // Ahora escuchamos cambios tanto en location.search como en location.state

  const handleSearch = async () => {
    if (!especialidad) return; // Siempre requerir especialidad
    if (!isSearchEnabled()) return;

    try {
      const searchParams = {
        modalidad,
        especialidad,
      };

      if (
        modalidad !== "remoto" &&
        modalidad !== "online" &&
        comuna &&
        comuna.trim() !== ""
      ) {
        searchParams.comuna = comuna;
      }

      const searchUrlParams = new URLSearchParams();
      searchUrlParams.append("m", modalidad);
      searchUrlParams.append("e", especialidad);
      if (searchParams.comuna) {
        searchUrlParams.append("c", comuna);
      }

      const searchUrl = `/busqueda?${searchUrlParams.toString()}`;
      window.history.pushState({}, "", searchUrl);

      const apiUrl = `/pacientes/buscarBusqueda?${searchUrlParams.toString()}`;

      setIsLoading(true);
      const response = await callApi(apiUrl);
      setSearchResponse(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error al realizar la búsqueda:", error);
    }
  };

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const filterOptions = (options, { inputValue }) => {
    const normalizedInput = normalizeString(inputValue);
    return options.filter((option) => {
      const normalizedOption = normalizeString(option);
      return normalizedOption.includes(normalizedInput);
    });
  };

  const filterComunaOptions = (options, { inputValue }) => {
    const normalizedInput = normalizeString(inputValue);
    return options.filter((option) => {
      const comunaName = option.split(",")[0];
      const normalizedOption = normalizeString(comunaName);
      return normalizedOption.includes(normalizedInput);
    });
  };

  const ProfessionalCard = ({ professional }) => {
    const { proximos_dias } = professional.disponibilidad;
    const dates = Object.keys(proximos_dias);
    const [selectedServiceType, setSelectedServiceType] = useState(
      modalityMapping[searchResponse?.busqueda_modalidad] || "consulta"
    );

    const formatDate = (dateStr) => {
      const date = new Date(`${dateStr}T12:00:00`);
      const months = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ];
      const days = [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ];

      return {
        dayName: days[date.getDay()],
        day: date.getDate(),
        month: months[date.getMonth()],
      };
    };

    const getDayName = (dateStr) => {
      const date = new Date(`${dateStr}T12:00:00`);
      const days = [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ];
      return days[date.getDay()];
    };

    const getServicesForType = (type) => {
      switch (type) {
        case "consulta":
          return {
            services: professional.servicios.consulta.servicios,
            additionalServices:
              professional.servicios.consulta.servicios_adicionales,
            location: professional.servicios.consulta.donde_atiende,
          };
        case "remoto":
          return {
            services: professional.servicios.remoto.servicios,
            additionalServices:
              professional.servicios.remoto.servicios_adicionales,
            location: professional.servicios.remoto.donde_atiende,
          };
        case "domicilio":
          return {
            services: professional.servicios.domicilio.servicios,
            additionalServices:
              professional.servicios.domicilio.servicios_adicionales,
            location: professional.servicios.domicilio.donde_atiende,
          };
        default:
          return {
            services: professional.servicios.consulta.servicios,
            additionalServices:
              professional.servicios.consulta.servicios_adicionales,
            location: professional.servicios.consulta.donde_atiende,
          };
      }
    };

    const disponibilidadSectionPC = (
      <>
        <Typography
          sx={{
            fontFamily: "Quicksand-Regular",
            mb: 2,
            fontSize: "14px",
          }}
        >
          {professional.disponibilidad.primer_dia_disponible ? (
            <>
              Próxima hora disponible el{" "}
              <span style={{ fontFamily: "Quicksand-Bold" }}>
                {
                  formatDate(professional.disponibilidad.primer_dia_disponible)
                    .dayName
                }{" "}
                {
                  formatDate(professional.disponibilidad.primer_dia_disponible)
                    .day
                }{" "}
                de{" "}
                {
                  formatDate(professional.disponibilidad.primer_dia_disponible)
                    .month
                }
              </span>
            </>
          ) : (
            <span style={{ color: "#6B7280", fontStyle: "italic" }}>
              No hay fechas disponibles
            </span>
          )}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 3,
            gap: 2,
          }}
        >
          {dates.map((date, index) => {
            const formattedDate = formatDate(date);
            const isAvailable = proximos_dias[date];
            const dayName = getDayName(date);

            return (
              <Box key={date} sx={{ flex: 1, textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Bold",
                    fontSize: "14px",
                  }}
                >
                  {dayName}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Regular",
                    color: "#6B7280",
                    fontSize: "14px",
                    mb: 1,
                  }}
                >
                  {formattedDate.day} {formattedDate.month}
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    bgcolor: "#F3F4F6",
                    py: 1,
                    px: 2,
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Quicksand-Regular",
                      fontSize: "14px",
                      color: "#374151",
                    }}
                  >
                    {isAvailable
                      ? "Horas disponibles"
                      : "Sin horas disponibles"}
                  </Typography>
                  {isAvailable && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        transform: "translate(50%, -50%)",
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        backgroundColor: "#10B981",
                        border: "2px solid white",
                      }}
                    />
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </>
    );

    const disponibilidadSection = professional.disponibilidad
      .primer_dia_disponible ? (
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontFamily: "Quicksand-Regular",
            mb: 2,
            fontSize: "14px",
          }}
        >
          Próxima hora disponible el{" "}
          <span style={{ fontFamily: "Quicksand-Bold" }}>
            {
              formatDate(professional.disponibilidad.primer_dia_disponible)
                .dayName
            }{" "}
            {formatDate(professional.disponibilidad.primer_dia_disponible).day}{" "}
            de{" "}
            {
              formatDate(professional.disponibilidad.primer_dia_disponible)
                .month
            }
          </span>
        </Typography>
      </Box>
    ) : (
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontFamily: "Quicksand-Regular",
            mb: 2,
            fontSize: "14px",
            color: "#6B7280",
            fontStyle: "italic",
          }}
        >
          No hay fechas disponibles
        </Typography>
      </Box>
    );

    return (
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: 2,
          p: 0,
          mb: 2,
          border: "3px solid #e5e7eb",
        }}
      >
        {isMobile ? (
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{ display: "flex", alignItems: "center", mb: 1, gap: 1 }}
              >
                <Typography
                  sx={{ fontFamily: "Quicksand-Bold", fontSize: "18px" }}
                >
                  {professional.nombre}
                </Typography>
                <CheckCircleIcon sx={{ color: "#10B981", fontSize: 18 }} />
              </Box>

              <Typography
                sx={{
                  fontFamily: "Quicksand-Regular",
                  color: "#6B7280",
                  mb: 1,
                }}
              >
                {professional.profesion}
              </Typography>

              <Box
                sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}
              >
                <Rating
                  value={Number(professional.evaluaciones.promedio)}
                  readOnly
                  size="small"
                />
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Regular",
                    fontSize: "14px",
                    color: "#6B7280",
                  }}
                >
                  {professional.evaluaciones.cantidad} opiniones
                </Typography>
              </Box>

              {/* Imagen */}
              <Box
                component="img"
                src={professional.url_foto}
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  mb: 3,
                }}
              />
            </Box>
            <Box
              sx={{
                mb: 1,
                display: "flex",
                flexDirection: "row",
                gap: 2,
                pb: 2,
                position: "relative",
              }}
            >
              {[
                { key: "consulta", label: "Presencial" },
                { key: "remoto", label: "Online" },
                { key: "domicilio", label: "Domicilio" },
              ].map((type) => (
                <Box
                  key={type.key}
                  onClick={() => setSelectedServiceType(type.key)}
                  sx={{
                    position: "relative",
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Quicksand-Regular",
                      fontSize: "14px",
                      color:
                        selectedServiceType === type.key ? "#000" : "#6B7280",
                      pb: 1,
                    }}
                  >
                    {type.label}
                  </Typography>

                  {selectedServiceType === type.key && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: -2,
                        left: 0,
                        right: 0,
                        height: "2px",
                        backgroundColor: "#000",
                        borderRadius: "2px",
                      }}
                    />
                  )}
                </Box>
              ))}
              {/* Línea inferior que cruza todas las opciones */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: "14px",
                  left: 0,
                  right: 0,
                  height: "1px",
                  backgroundColor: "#e5e7eb",
                  zIndex: 1,
                }}
              />
            </Box>
            {/* Servicios */}
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontFamily: "Quicksand-Regular",
                  color: "#6B7280",
                  mb: 2,
                  fontSize: "14px",
                }}
              >
                Donde: {getServicesForType(selectedServiceType).location}
              </Typography>

              {getServicesForType(selectedServiceType).services.map(
                (service, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Quicksand-Regular",
                        fontSize: "14px",
                      }}
                    >
                      {service.nombre}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Quicksand-Regular",
                        fontSize: "14px",
                      }}
                    >
                      {service.precio}
                    </Typography>
                  </Box>
                )
              )}

              {getServicesForType(selectedServiceType).additionalServices >
                0 && (
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Quicksand-Regular",
                    color: "#6B7280",
                    fontSize: "14px",
                    mt: 1,
                  }}
                >
                  y {getServicesForType(selectedServiceType).additionalServices}{" "}
                  servicios más
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                borderBottom: "3px solid #e5e7eb",
                mb: 3,
              }}
            />
            {disponibilidadSection}

            {/* Botón de agendar */}
            <Button
              variant="contained"
              fullWidth
              onClick={() => (window.location.href = professional.url_perfil)}
              sx={{
                backgroundColor: "#10B981",
                fontFamily: "Quicksand-Regular",
                textTransform: "none",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#059669",
                },
                py: 1.5,
                borderRadius: 1,
              }}
            >
              Ver profesional y agendar
            </Button>
          </Box>
        ) : (
          // Versión PC de la card (el código actual)
          <Box sx={{ display: "flex", p: 3, gap: 4 }}>
            {/* Left section */}
            <Box sx={{ width: "25%" }}>
              <Box
                sx={{ display: "flex", alignItems: "center", mb: 1, gap: 1 }}
              >
                <Typography
                  sx={{ fontFamily: "Quicksand-Bold", fontSize: "18px" }}
                >
                  {professional.nombre}
                </Typography>
                <CheckCircleIcon sx={{ color: "#10B981", fontSize: 18 }} />
              </Box>

              <Typography
                sx={{
                  fontFamily: "Quicksand-Regular",
                  color: "#6B7280",
                  mb: 1,
                }}
              >
                {professional.profesion}
              </Typography>

              <Box
                sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}
              >
                <Rating
                  value={Number(professional.evaluaciones.promedio)}
                  readOnly
                  size="small"
                />
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Regular",
                    fontSize: "14px",
                    color: "#6B7280",
                  }}
                >
                  {professional.evaluaciones.cantidad} opiniones
                </Typography>
              </Box>

              <Box
                component="img"
                src={professional.url_foto}
                sx={{
                  width: "250px",
                  borderRadius: 1,
                }}
              />
            </Box>
            <Box
              sx={{
                borderRight: "3px solid #e5e7eb",
              }}
            />
            <Box sx={{ width: "40%" }}>
              <Box sx={{ position: "relative" }}>
                <Box sx={{ display: "flex", gap: 1, position: "relative" }}>
                  {[
                    { key: "consulta", label: "Presencial" },
                    { key: "remoto", label: "Online" },
                    { key: "domicilio", label: "Domicilio" },
                  ].map((type) => (
                    <Typography
                      key={type.key}
                      onClick={() => setSelectedServiceType(type.key)}
                      sx={{
                        fontFamily: "Quicksand-Regular",
                        fontSize: "14px",
                        color:
                          selectedServiceType === type.key ? "#000" : "#6B7280",
                        borderBottom:
                          selectedServiceType === type.key
                            ? "2px solid #000"
                            : "none",
                        pb: 1,
                        cursor: "pointer",
                        transition: "all 0.2s",
                        position: "relative",
                        zIndex: 2,
                        "&:hover": {
                          color:
                            selectedServiceType === type.key ? "#000" : "#000",
                        },
                      }}
                    >
                      {type.label}
                    </Typography>
                  ))}
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "1px",
                    left: 0,
                    right: 0,
                    height: "1px",
                    backgroundColor: "#e5e7eb",
                    zIndex: 1,
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Quicksand-Regular",
                  color: "#6B7280",
                  mb: 2,
                  mt: 3,
                  fontSize: "14px",
                }}
              >
                Donde: {getServicesForType(selectedServiceType).location}
              </Typography>

              {getServicesForType(selectedServiceType).services.map(
                (service, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Quicksand-Regular",
                        fontSize: "14px",
                      }}
                    >
                      {service.nombre}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Quicksand-Regular",
                        fontSize: "14px",
                      }}
                    >
                      {service.precio}
                    </Typography>
                  </Box>
                )
              )}

              {getServicesForType(selectedServiceType).additionalServices >
                0 && (
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Quicksand-Regular",
                    color: "#6B7280",
                    fontSize: "14px",
                    mt: 1,
                  }}
                >
                  y {getServicesForType(selectedServiceType).additionalServices}{" "}
                  servicios más
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                borderRight: "3px solid #e5e7eb",
              }}
            />
            {/* Right section */}
            <Box sx={{ width: "35%" }}>
              {disponibilidadSectionPC}

              <Button
                variant="contained"
                fullWidth
                onClick={() => (window.location.href = professional.url_perfil)}
                sx={{
                  backgroundColor: "#10B981",
                  fontFamily: "Quicksand-Regular",
                  textTransform: "none",
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: "#059669",
                  },
                  py: 1.5,
                  borderRadius: 1,
                }}
              >
                Ver profesional y agendar
              </Button>
            </Box>
          </Box>
        )}{" "}
      </Box>
    );
  };

  const searchButtonStyles = {
    backgroundColor: isSearchEnabled() ? "#10B981" : "#E5E7EB",
    fontFamily: "Quicksand-Bold",
    textTransform: "none",
    height: 40,
    px: 4,
    "&:hover": {
      backgroundColor: isSearchEnabled() ? "#059669" : "#E5E7EB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#E5E7EB",
      color: "#9CA3AF",
    },
  };

  return (
    <Container maxWidth="lg">
      {isMobile ? <MenuProfesiones /> : <MenuClientea />}
      <Box
        sx={{
          bgcolor: "#4669E8",
          p: 2,
          borderRadius: 1,
          mb: 2,
          mt: isMobile ? 2 : 10,
        }}
      >
        {isLoading ? (
          <Skeleton
            width="40%"
            height={32}
            sx={{
              mb: isMobile ? 0 : 2,
              bgcolor: "rgba(255, 255, 255, 0.3)",
            }}
          />
        ) : (
          <Typography
            sx={{
              fontFamily: "Quicksand-Bold",
              color: "white",
              mb: isMobile ? 0 : 2,
            }}
          >
            {searchResponse?.busqueda_especialidad},{" "}
            {searchResponse?.busqueda_comuna}
          </Typography>
        )}

        {!isMobile && (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Autocomplete
              value={searchResponse?.busqueda_modalidad || ""}
              onChange={(event, newValue) => {
                setModalidad(modalityMapping[newValue] || modalidad); // Si no hay mapeo, mantiene el valor actual
              }}
              inputValue={inputModalidad}
              onInputChange={(event, newInputValue) => {
                setInputModalidad(newInputValue);
              }}
              options={searchOptions.modalidades || []}
              filterOptions={filterOptions}
              freeSolo
              noOptionsText="No se encontraron resultados"
              sx={{
                backgroundColor: "white",
                width: 200,
                ".MuiOutlinedInput-root": {
                  height: 40,
                  py: 0,
                  "& input": {
                    py: 0,
                    fontFamily: "Quicksand-Bold",
                  },
                },
                ".MuiAutocomplete-option": {
                  fontFamily: "Quicksand-Bold",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Modalidad de atención"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />

            <Autocomplete
              value={searchResponse?.busqueda_especialidad || ""}
              onChange={(event, newValue) => {
                setEspecialidad(newValue);
              }}
              inputValue={inputProfession}
              onInputChange={(event, newInputValue) => {
                setInputProfession(newInputValue);
              }}
              options={searchOptions.especialidades}
              filterOptions={filterOptions}
              freeSolo
              noOptionsText="No se encontraron resultados"
              sx={{
                flex: 1,
                backgroundColor: "white",
                width: 200,
                ".MuiOutlinedInput-root": {
                  height: 40,
                  py: 0,
                  "& input": {
                    py: 0,
                    fontFamily: "Quicksand-Bold",
                  },
                },
                ".MuiAutocomplete-option": {
                  fontFamily: "Quicksand-Bold",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Profesión o especialidad"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />

            <Autocomplete
              value={searchResponse?.busqueda_comuna || ""}
              onChange={(event, newValue) => {
                setComuna(newValue || "");
              }}
              inputValue={inputComuna}
              onInputChange={(event, newInputValue) => {
                setInputComuna(newInputValue);
              }}
              options={searchOptions.comunas}
              filterOptions={filterComunaOptions}
              freeSolo
              noOptionsText="No se encontraron resultados"
              sx={{
                flex: 1,
                backgroundColor: "white",
                width: 200,
                ".MuiOutlinedInput-root": {
                  height: 40,
                  py: 0,
                  "& input": {
                    py: 0,
                    fontFamily: "Quicksand-Bold",
                  },
                },
                ".MuiAutocomplete-option": {
                  fontFamily: "Quicksand-Bold",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Ciudad o comuna"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              disabled={!isSearchEnabled()}
              sx={searchButtonStyles}
            >
              Buscar
            </Button>
          </Box>
        )}
      </Box>
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: 2,
            mt: 2,
          }}
        >
          <Link href="/" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
                color: "#4669E8",
                fontFamily: "Quicksand-Bold",
                fontSize: "14px",
              }}
            >
              Hacer otra búsqueda
            </Typography>
          </Link>
        </Box>
      )}
      {/* Alert message */}
      {searchResponse?.alerta_estado && (
        <Box
          sx={{
            bgcolor: "#FFEDD5",
            color: "#9A3412",
            p: 2,
            borderRadius: 1,
            mb: 2,
            fontFamily: "Quicksand-Regular",
            fontStyle: "italic",
          }}
        >
          {searchResponse.alerta_mensaje}
        </Box>
      )}

      {isLoading ? (
        <>
          <LoadingSkeleton />
          <LoadingSkeleton />
          <LoadingSkeleton />
        </>
      ) : (
        // Professional cards
        searchResponse?.profesionales.map((professional, index) => (
          <ProfessionalCard key={index} professional={professional} />
        ))
      )}
    </Container>
  );
};

export default SearchResults;
