import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Divider } from "@mui/material";
import callApi from "../../services/callApi";
import Loader from "../../components/Loader/Loader";
import logoImage from "../../assets/logo.png";

const CodeVerification = ({ userData, onNext, onBack }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  console.log(userData);

  useEffect(() => {
    // Solo habilitar el botón si el código tiene exactamente 4 dígitos
    setIsButtonEnabled(code.length === 4 && /^\d{4}$/.test(code));
  }, [code]);

  const handleCodeChange = (e) => {
    const value = e.target.value;
    // Solo permitir números y limitar a 4 dígitos
    if (value === "" || /^\d{0,4}$/.test(value)) {
      setCode(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (code.length !== 4 || !/^\d{4}$/.test(code)) {
      setError("El código debe ser de 4 dígitos");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const response = await callApi("/registro/crearCuenta", "post", {
        email: userData.email,
        telefono: userData.telefono, // Aseguramos que usamos 'telefono'
        password: userData.password,
        rut: userData.rut, // Incluimos el RUT
        nombres: userData.nombres,
        apellidos: userData.apellidos,
        profesion: userData.profesion,
        code: code,
      });

      if (response?.statusCode === 200 || response?.statusCode === 201) {
        onNext();
      } else {
        setError("Código inválido. Por favor, verifica e intenta nuevamente.");
      }
    } catch (error) {
      console.error("Error al crear cuenta:", error);
      setError(
        error.response?.data?.message ||
          "Código inválido. Por favor, verifica e intenta nuevamente."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    setIsLoading(true);
    setError("");

    try {
      const response = await callApi("/registro/enviarCodigo", "post", {
        email: userData.email,
        telefono: userData.telefono, // Corregido de 'telefeno' a 'telefono'
        password: userData.password,
        rut: userData.rut, // Incluimos el RUT
        nombres: userData.nombres,
        apellidos: userData.apellidos,
        profesion: userData.profesion,
      });
      if (response?.statusCode === 200 || response?.status === 201) {
        setError("Se ha enviado un nuevo código a tu email");
        setCode("");
      } else {
        setError("Error al reenviar el código. Por favor, intenta nuevamente.");
      }
    } catch (error) {
      console.error("Error al reenviar código:", error);
      setError(
        error.response?.data?.message ||
          "Error al reenviar el código. Por favor, intenta nuevamente."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const inputSx = {
    mb: 1,
    "& .MuiInputBase-root": {
      height: 40,
      backgroundColor: "#f5f8fa",
      borderRadius: 1,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#dfe3e8",
      },
      "&:hover fieldset": {
        borderColor: "#b0b8c4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1877f2",
      },
    },
    "& .MuiInputBase-input": {
      padding: "8px 14px",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 9px) scale(1)",
      fontFamily: "Quicksand-Regular",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputLabel-asterisk": {
      display: "none",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          p: 3,
          borderRadius: 2,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          bgcolor: "background.paper",
        }}
      >
        <Loader isLoading={isLoading} />
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={logoImage} alt="Teradomus" style={{ height: "40px" }} />
        </Box>

        <Typography
          variant="h6"
          sx={{
            color: "#1877f2",
            mb: 1,
            fontSize: "20px",
            fontFamily: "Quicksand-Bold",
          }}
        >
          Estás a un paso de crear tu cuenta 👌🏻
        </Typography>
        <Divider sx={{ width: "100%", mb: 2 }} />

        <Typography
          variant="body2"
          sx={{
            mb: 2,
            color: "#65676b",
            fontSize: "14px",
            fontFamily: "Quicksand-Regular",
            alignSelf: "flex-start",
          }}
        >
          Solo nos falta validar tu email, por favor ingresa el código de 4
          dígitos que acabamos de enviar a{" "}
          <Box
            component="span"
            sx={{
              color: "#1877f2",
              fontWeight: 500,
            }}
          >
            {userData?.email}
          </Box>
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Código"
            value={code}
            onChange={handleCodeChange}
            margin="normal"
            required
            inputProps={{
              maxLength: 4,
              pattern: "\\d{4}",
            }}
            placeholder="0000"
            sx={inputSx}
          />

          {error && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "14px",
                fontFamily: "Quicksand-Regular",
                textAlign: "center",
                mt: 2,
                p: 1,
                bgcolor: "rgba(211, 47, 47, 0.1)",
                borderRadius: 1,
              }}
            >
              {error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isButtonEnabled}
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: "#1877f2",
              "&:hover": { bgcolor: "#166fe5" },
              "&.Mui-disabled": {
                bgcolor: "rgba(24, 119, 242, 0.5)",
                color: "white",
              },
              textTransform: "none",
              fontFamily: "Quicksand-Bold",
              height: 40,
            }}
          >
            Empezar gratis
          </Button>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 2,
            }}
          >
            <Button
              variant="text"
              sx={{
                color: "#1877f2",
                textTransform: "none",
                fontFamily: "Quicksand-Regular",
                fontSize: "14px",
              }}
              onClick={onBack}
            >
              volver
            </Button>
            <Button
              onClick={handleResendCode}
              variant="text"
              sx={{
                color: "#1877f2",
                textTransform: "none",
                fontFamily: "Quicksand-Regular",
                fontSize: "14px",
              }}
            >
              reenviar código
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default CodeVerification;
