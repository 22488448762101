import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import callApi from "../../services/callApi";
import { useHistory } from "react-router-dom";
import MenuProfesiones from "../../components/MenuCliente/MenuSinServicios";
import MenuClientea from "../../components/MenuCliente/MenuCliente";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

const SearchSection = () => {
  const [searchOptions, setSearchOptions] = useState({
    n_profesionales: 0,
    especialidades: [],
    comunas: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [inputProfession, setInputProfession] = useState("");
  const [inputComuna, setInputComuna] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [selectedComuna, setSelectedComuna] = useState("");
  const [serviceType, setServiceType] = useState("presencial");
  const history = useHistory();

  useEffect(() => {
    const fetchSearchOptions = async () => {
      setIsLoading(true);
      try {
        const response = await callApi("/pacientes/buscadorOpciones");
        setSearchOptions({
          n_profesionales: response.n_profesionales,
          especialidades: response.especialidades,
          comunas: response.comunas,
        });
      } catch (error) {
        console.error("Error al obtener opciones de búsqueda:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSearchOptions();
  }, []);

  const handleServiceTypeChange = (type) => {
    setServiceType(type);
  };

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const filterOptions = (options, { inputValue }) => {
    const normalizedInput = normalizeString(inputValue);
    return options.filter((option) => {
      const normalizedOption = normalizeString(option);
      return normalizedOption.includes(normalizedInput);
    });
  };

  const filterComunaOptions = (options, { inputValue }) => {
    const normalizedInput = normalizeString(inputValue);
    return options.filter((option) => {
      // Tomamos solo la parte antes de la coma para la búsqueda
      const comunaName = option.split(",")[0];
      const normalizedOption = normalizeString(comunaName);
      return normalizedOption.includes(normalizedInput);
    });
  };

  const isSearchEnabled = () => {
    if (serviceType === "online") {
      return selectedProfession; // Solo requiere la especialidad si es online
    }
    return serviceType && selectedProfession && selectedComuna; // Requiere todos los campos para presencial y domicilio
  };

  const handleSearch = () => {
    const searchParams = {
      modalidad: serviceType || "",
      especialidad: selectedProfession || "",
      comuna: selectedComuna || "",
    };

    // Construimos la URL para el historial del navegador
    const searchUrl = `/busqueda?m=${encodeURIComponent(
      searchParams.modalidad
    )}&e=${encodeURIComponent(
      searchParams.especialidad
    )}&c=${encodeURIComponent(searchParams.comuna)}`;

    // Navegamos usando history con state
    history.push({
      pathname: "/busqueda",
      search: `?m=${encodeURIComponent(
        searchParams.modalidad
      )}&e=${encodeURIComponent(
        searchParams.especialidad
      )}&c=${encodeURIComponent(searchParams.comuna)}`,
      state: searchParams,
    });
  };

  const pageTitle = "Teradomus";
  const pageDescription = "Elige tu servicio: Recupera tu bienestar.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Container maxWidth="lg">
        {isMobile ? <MenuProfesiones /> : <MenuClientea />}
        <Box
          sx={{
            width: { xs: "auto", md: "auto%" },
            backgroundColor: "#4669E8",
            borderRadius: "8px",
            p: { xs: 1, md: 6 },
            mb: 8,
            mt: isMobile ? 2 : 13,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "24px", md: "32px" },
              color: "white",
              mb: 1,
              whiteSpace: { xs: "pre-line", md: "normal" },
            }}
          >
            {isMobile
              ? "Encuentra tu\nprofesional de la salud"
              : "Encuentra tu profesional de la salud"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Quicksand-Regular",
              fontSize: { xs: "14px", md: "16px" },
              color: "white",
              mb: 3,
            }}
          >
            {`+${searchOptions.n_profesionales} profesionales están aquí para ayudarte`}
          </Typography>

          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                mb: 2,
              }}
            >
              {["presencial", "online", "domicilio"].map((type) => (
                <Button
                  key={type}
                  onClick={() => handleServiceTypeChange(type)}
                  sx={{
                    backgroundColor:
                      serviceType === type
                        ? "white"
                        : "rgba(255, 255, 255, 0.1)",
                    color: serviceType === type ? "black" : "white",
                    textTransform: "none",
                    fontFamily: "Quicksand-Regular",
                    "&:hover": {
                      backgroundColor:
                        serviceType === type
                          ? "white"
                          : "rgba(255, 255, 255, 0.1)",
                    },
                    px: 3,
                  }}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Button>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Autocomplete
                value={selectedProfession}
                onChange={(event, newValue) => {
                  setSelectedProfession(newValue);
                }}
                inputValue={inputProfession}
                onInputChange={(event, newInputValue) => {
                  setInputProfession(newInputValue);
                }}
                options={searchOptions.especialidades}
                filterOptions={filterOptions}
                freeSolo
                noOptionsText="No se encontraron resultados"
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  borderRadius: 1,
                  "& .MuiOutlinedInput-root": {
                    py: 0,
                    "& input": {
                      py: 1.5,
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Profesión o especialidad"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                )}
              />

              <Autocomplete
                value={selectedComuna}
                onChange={(event, newValue) => {
                  setSelectedComuna(newValue);
                }}
                inputValue={inputComuna}
                onInputChange={(event, newInputValue) => {
                  setInputComuna(newInputValue);
                }}
                options={searchOptions.comunas}
                filterOptions={filterComunaOptions} // Aquí usamos la nueva función
                freeSolo
                noOptionsText="No se encontraron resultados"
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  borderRadius: 1,
                  "& .MuiOutlinedInput-root": {
                    py: 0,
                    "& input": {
                      py: 1.5,
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Ciudad o comuna"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                )}
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={!isSearchEnabled()}
                sx={{
                  backgroundColor: isSearchEnabled() ? "#10B981" : "#E5E7EB",
                  fontFamily: "Quicksand-Bold",
                  textTransform: "none",
                  px: 4,
                  "&:hover": {
                    backgroundColor: isSearchEnabled() ? "#059669" : "#E5E7EB",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#E5E7EB",
                    color: "#9CA3AF",
                  },
                }}
              >
                Buscar
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Features Section */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "repeat(4, 1fr)" },
            gap: 4,
            mb: 8,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "18px", md: "18px" },
                color: "#111827",
                mb: 1,
              }}
            >
              Encuentra tu especialista
            </Typography>
            <Typography
              sx={{
                fontFamily: "Quicksand-Regular",
                fontSize: { xs: "14px", md: "16px" },
                color: "#6B7280",
                lineHeight: 1.5,
              }}
            >
              Trabajamos solo con profesionales autorizados por la
              superintendencia de salud.
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "18px", md: "18px" },
                color: "#111827",
                mb: 1,
              }}
            >
              Pide tu cita de forma fácil
            </Typography>
            <Typography
              sx={{
                fontFamily: "Quicksand-Regular",
                fontSize: { xs: "14px", md: "16px" },
                color: "#6B7280",
                lineHeight: 1.5,
              }}
            >
              Elige el servicio que prefieras y pide reserva tu hora de forma
              fácil sin tener que llamar.
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "18px", md: "18px" },
                color: "#111827",
                mb: 1,
              }}
            >
              Recordatorios por WhatsApp
            </Typography>
            <Typography
              sx={{
                fontFamily: "Quicksand-Regular",
                fontSize: { xs: "14px", md: "16px" },
                color: "#6B7280",
                lineHeight: 1.5,
              }}
            >
              Te confirmamos la cita al instante por email y te enviaremos
              varios recordatorios a tu WhatsApp antes de la cita.
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "18px", md: "18px" },
                color: "#111827",
                mb: 1,
              }}
            >
              Programa tu seguimiento
            </Typography>
            <Typography
              sx={{
                fontFamily: "Quicksand-Regular",
                fontSize: { xs: "14px", md: "16px" },
                color: "#6B7280",
                lineHeight: 1.5,
              }}
            >
              Agenda fácilmente tus próximas citas y mantén el control de tus
              tratamientos directamente con tu profesional.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SearchSection;
