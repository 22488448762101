import React, { useState, useRef } from "react";
import { Box, Container } from "@mui/material";
import { isMobile } from "react-device-detect";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "../../components/Loader";
import "./DemoKine.css";
import { Helmet } from "react-helmet";

import KinesiologoIntro from "./Components/KinesiologoIntro";
import PatientCareFeatures from "./Components/Cajas";
import MenuTeradomus from "../../components/MenuCliente/MenuProfesiones";
import TestimoniosProfesionales from "./Components/Testimonios";
import Footer from "../../pages/DemoKine/Components/FooterProfesion";
import PricingSection from "./Components/Planes";
import ContactFormComponent from "./Components/ContactForm";

export default function DemoKine() {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = React.useState(false);
  const isDesktop = !isMobile;
  const [cellNumber, setCellNumber] = useState("");

  const pageTitle = `Teradomus`;
  const pageDescription = `Teradomus: Revoluciona tus servicios de kinesiología con agendamiento fácil, pagos automáticos y inteligencia artificial. Profesionaliza y digitaliza tu consulta hoy.`;

  const pricingSectionRef = useRef(null);

  const scrollToPricing = () => {
    pricingSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/pro" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://www.teradomus.com/pro" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Article",
            headline: pageTitle,
            description: pageDescription,
            url: "https://www.teradomus.com/pro",
          })}
        </script>
      </Helmet>
      <div className="gradient-background">
        <Loader isLoading={isLoading} />
        <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 0, md: 10 } }}>
          <MenuTeradomus scrollToPricing={scrollToPricing} />
          <KinesiologoIntro />
          <PatientCareFeatures />
          <TestimoniosProfesionales />
          {isMobile ? (
            <div ref={pricingSectionRef}>
              <PricingSection />
            </div>
          ) : (
            <>
              <Box ref={pricingSectionRef} sx={{ mb: 15 }}>
                {""}
              </Box>
              <PricingSection />
            </>
          )}

          <ContactFormComponent />
          <Footer />
        </Container>
      </div>
    </>
  );
}
