import { Switch, Route } from "react-router-dom";
import Box from "@mui/material/Box";

import Menu from "../components/Menu/";
import Footer from "../components/Footer/";

import Home from "../pages/Home/";
import Suscribe from "../pages/Suscribe/";
import SuscribeDone from "../pages/SuscribeDone/SuscribeDone";
import Petition from "../pages/Petition/Petition";
import HomeCliente from "../pages/HomeCliente/HomeCliente";
import MenuCliente from "../components/MenuCliente/MenuCliente";
import MenuClientea from "../components/MenuCliente/MenuClientea";
import FooterCliente from "../components/FooterCliente/FooterCliente";
import Form1 from "../pages/Request/Form1/Form1";
import Form2 from "../pages/Request/Form2/Form2";
import Form4 from "../pages/Request/Form4/Form4";
import Form5 from "../pages/Request/Form5/Form5";
import Form6 from "../pages/Request/Form6";
import Form9 from "../pages/Request/Form9/Form9";
import Form8 from "../pages/Request/Form8";
import Payment from "../pages/Payment/Payment";
import Prueba from "../pages/Prueba/Prueba";
import Prueba2 from "../pages/Prueba/FormInteligente";
import Vive from "../pages/Prueba/Vive";
import Servicios from "../pages/Prueba/Servicios";
import Calendario from "../pages/Prueba/Calendario";
import Adultomayor from "../pages/Adultomayor/Adultomayor";
import PlanA from "../pages/Adultomayor/PlanA";
import PlanB from "../pages/Adultomayor/PlanB";
import PlanC from "../pages/Adultomayor/PlanC";
import TipoKine from "../pages/Prueba/TipoKine";
import TiposKines from "../pages/Prueba/TiposKines";
import Secciones from "../pages/Prueba/Secciones";
import Solicitar from "../pages/Prueba/Solicitar";
import Ingresada from "../pages/Prueba/Ingresada";
import Blog from "../components/MenuCliente/Blog";
import BlogNoticia from "../components/MenuCliente/BlogNoticia";
import BlogNoticia2 from "../components/MenuCliente/BlogNoticia2";
import MenuSinServicios from "../components/MenuCliente/MenuSinServicios";
import BlogAgendamiento from "../components/MenuCliente/BlogAgendamiento";
import BlogSomos from "../components/MenuCliente/BlogSomos";
import NotFound from "../pages/NotFound/NotFound";
import Video from "../pages/Video/Video";
import HomeClienteComunas from "../pages/HomeCliente/HomeClienteComunas";
import DemoKine from "../pages/DemoKine/DemoKine";
import Exapande from "../pages/Video/Exapande";
import Profesionales from "../pages/profesionales/profesional";
import Sesiones from "../pages/profesionales/sesiones";
import CalendarioPeril from "../pages/profesionales/calendario";
import DatosPaciente from "../pages/profesionales/DatosPaciente";
import IngresadaPerfil from "../pages/profesionales/Ingresada";
import IngresadaMercadoPago from "../pages/profesionales/IngresadaMercadoPago";
import CenteredImage from "../pages/Request/ImagenPag/ImagenPag";
import EvaluacionForm from "../pages/EvaluacionProfesionales/Evaluacion";
import SearchResults from "../pages/HomeCliente/buscadorProfesionales";
import RegistrationFlow from "../pages/Registro/Registracion";

function Routes() {
  return (
    <Switch>
      <Route exact path="/inscripcion">
        <Menu />
        <Home />
        <Footer />
      </Route>

      <Route exact path="/suscribe">
        <Menu />
        <Box style={{ overflowX: "hidden" }}>
          <Suscribe />
        </Box>
        <Box style={{ overflowX: "hidden" }}>
          <Footer />
        </Box>{" "}
      </Route>

      <Route exact path="/blog">
        <MenuSinServicios />
        <Blog />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/blog/calefaccion">
        <MenuSinServicios />
        <BlogNoticia />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/blog/domicilio">
        <MenuSinServicios />
        <BlogNoticia2 />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/blog/agendamiento">
        <MenuSinServicios />
        <BlogAgendamiento />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/blog/somos">
        <MenuSinServicios />
        <BlogSomos />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/suscribe-done">
        <Menu />
        <SuscribeDone />
        <Footer />
      </Route>

      {/*<Route exact path="/">
        <MenuCliente />
        <Principal />
        <Box style={{ overflowX: 'hidden' }}><FooterCliente /></Box>
  </Route>*/}

      <Route exact path="/">
        <HomeCliente />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/kinesiologia-a-domicilio">
        <HomeCliente />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/kinesiologia-a-domicilio/:comunaUrl">
        <MenuCliente />
        <HomeClienteComunas />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/solicitud">
        <Menu />
        <Petition />
        <Footer />
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda">
        <Form1 />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda/atencion">
        <MenuCliente />
        <Box style={{ overflowX: "hidden" }}>
          {" "}
          <Form2 />
        </Box>
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda/calendario">
        <MenuCliente />
        <Box style={{ overflowX: "hidden" }}>
          {" "}
          <Form4 />
        </Box>
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda/quien">
        <MenuCliente />
        <Form5 />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda/para-mi">
        <MenuCliente />
        <Form6 />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda/para-otro">
        <MenuCliente />
        <Form8 />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda/done">
        <MenuCliente />
        <Form9 />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/payment">
        <Payment />
      </Route>

      <Route exact path="/prueba">
        <Prueba />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda-inteligente/inicio">
        <Prueba2 />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route exact path="/kinesiologia-a-domicilio/agenda-inteligente/tipokine">
        <TipoKine />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route
        exact
        path="/kinesiologia-a-domicilio/agenda-inteligente/tiposkines"
      >
        <TiposKines />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route
        exact
        path="/kinesiologia-a-domicilio/agenda-inteligente/secciones"
      >
        <MenuCliente />
        <Secciones />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route
        exact
        path="/kinesiologia-a-domicilio/agenda-inteligente/direccion"
      >
        <Vive />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route
        exact
        path="/kinesiologia-a-domicilio/agenda-inteligente/servicios"
      >
        <Servicios />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route
        exact
        path="/kinesiologia-a-domicilio/agenda-inteligente/calendario"
      >
        <MenuCliente />
        <Calendario />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route
        exact
        path="/kinesiologia-a-domicilio/agenda-inteligente/solicitar"
      >
        <MenuCliente />
        <Solicitar />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>

      <Route
        exact
        path="/kinesiologia-a-domicilio/agenda-inteligente/ingresada"
      >
        <Ingresada />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/planes-adulto-mayor">
        <MenuClientea />
        <Box style={{ overflowX: "hidden" }}>
          <Adultomayor />
        </Box>
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/planes-adulto-mayor/fortaleza-y-movilidad">
        <MenuClientea />
        <Box style={{ overflowX: "hidden" }}>
          <PlanA />
        </Box>
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/planes-adulto-mayor/compañia-y-bienestar">
        <MenuClientea />
        <Box style={{ overflowX: "hidden" }}>
          <PlanB />
        </Box>
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/planes-adulto-mayor/bienestar-integral">
        <MenuClientea />
        <Box style={{ overflowX: "hidden" }}>
          <PlanC />
        </Box>
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route exact path="/pro">
        <Box style={{ overflowX: "hidden" }}>
          <DemoKine />
        </Box>
      </Route>
      <Route exact path="/pro/registro">
        <Box style={{ overflowX: "hidden" }}>
          <RegistrationFlow />
        </Box>
      </Route>

      <Route exact path="/video_2">
        <MenuClientea />
        <Video />
      </Route>
      <Route exact path="/video_exapande">
        <MenuClientea />
        <Exapande />
      </Route>
      <Route exact path="/ejemploimagenpro">
        <CenteredImage />
      </Route>

      <Route exact path="/pro/:comunaUrl">
        <Profesionales />
      </Route>
      <Route exact path="/pro/perfil/sesiones">
        <Sesiones />
      </Route>

      <Route exact path="/pro/perfil/calendario">
        <CalendarioPeril />
      </Route>

      <Route exact path="/pro/perfil/datos">
        <DatosPaciente />
      </Route>

      <Route exact path="/pro/perfil/exito">
        <IngresadaPerfil />
      </Route>
      <Route exact path="/pro/evaluacion/:id">
        <EvaluacionForm />
      </Route>
      <Route path="/:searchParams">
        <SearchResults />
        <Box style={{ overflowX: "hidden" }}>
          <FooterCliente />
        </Box>
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
