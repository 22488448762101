import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import logo from "../../assets/images/Teradomus_blanco_2.png";
import { Link } from "@material-ui/core";

import Zap from "../../assets/newiconos/zap.png";
import Gob from "../../assets/newiconos/gob.png";
import Mer from "../../assets/newiconos/mer.png";
import ins from "../../assets/newiconos/insta.png";
import lin from "../../assets/newiconos/lin.png";

export default function FooterCliente() {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  function Antes() {
    if (isMobile === false) {
      return (
        <Box sx={{ backgroundColor: "#4669E8", mt: 10 }}>
          <Container>
            <Grid
              container
              spacing={2}
              sx={{ pt: 2, pb: 3, textAlign: "left", color: "white" }}
            >
              <Grid item md={6}>
                <Link href="/">
                  <Box
                    component="img"
                    alt="logo teradomus"
                    sx={{
                      width: "40%",
                    }}
                    src={logo}
                  />
                </Link>
              </Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "right",
                    gap: "15px",
                  }}
                >
                  <img
                    src={Zap}
                    alt="Sello Empresa"
                    style={{ width: "auto", height: "100px" }}
                  />
                  <img
                    src={Gob}
                    alt="Gobierno de Chile"
                    style={{ width: "auto", height: "100px" }}
                  />
                  <img
                    src={Mer}
                    alt="Mercado LIbre"
                    style={{ width: "auto", height: "100px" }}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Typography
                  sx={{ fontFamily: "Quicksand-Bold", fontSize: "16px" }}
                >
                  ¿Tienes dudas o necesitas ayuda para agendar?
                </Typography>
                <Typography
                  mt={2}
                  sx={{ fontFamily: "Quicksand-Light", fontSize: "16px" }}
                >
                  <Link href="tel:56996906773" underline="none" color="inherit">
                    Llámanos por teléfono
                  </Link>
                  <br></br>
                  <Link
                    href="https://api.whatsapp.com/send/?phone=%2B56996906773&text=Hola,%20amigos%20de%20Teradomus.%20Quiero%20agendar%20una%20cita%20con%20un%20profesional%20de%20la%20salud.%20¿Podrían%20ayudarme%20😊?&type=phone_number&app_absent=0"
                    underline="none"
                    color="inherit"
                  >
                    Escríbenos al WhatsApp<br></br> +56 9 9690 6773
                  </Link>
                </Typography>
              </Grid>
              <Grid item md={2}></Grid>
              <Grid item md={2.5}>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Light",
                    fontSize: "18px",
                    pt: 3,
                    color: "#4669E8",
                  }}
                >
                  <b>Legal</b>
                </Typography>
                <Link
                  href="https://teradomus-storage-public.s3.amazonaws.com/NO_BORRAR/CONDICIONES_CLIENTES.pdf"
                  underline="none"
                  color="inherit"
                >
                  <Typography
                    mt={2}
                    sx={{ fontFamily: "Quicksand-Light", fontSize: "16px" }}
                  >
                    Términos y condiciones<br></br>Políticas de privacidad
                  </Typography>
                </Link>
              </Grid>
              <Grid item md={2}>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Light",
                    fontSize: "16px",
                    pt: 3,
                    color: "#4669E8",
                  }}
                >
                  <b>Recursos</b>
                </Typography>
                <Typography
                  mt={2}
                  sx={{ fontFamily: "Quicksand-Light", fontSize: "16px" }}
                >
                  <Link href="/blog" underline="none" color="inherit">
                    Blog<br></br>
                  </Link>
                  <Link
                    href="https://teradomus-storage-public.s3.amazonaws.com/NO_BORRAR/FAQ.pdf"
                    underline="none"
                    color="inherit"
                  >
                    Preguntas frecuentes
                  </Link>
                </Typography>
              </Grid>
              <Grid item md={2.5}>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Light",
                    fontSize: "16px",
                    pt: 3,
                    color: "#4669E8",
                  }}
                >
                  <b>Acerca de</b>
                </Typography>
                <Link
                  href="https://teradomus.com/pro"
                  underline="none"
                  color="inherit"
                >
                  <Typography
                    mt={2}
                    sx={{ fontFamily: "Quicksand-Light", fontSize: "16px" }}
                  >
                    Atiende con Teradomus
                  </Typography>
                </Link>
              </Grid>
              <Grid item md={12}>
                <Box
                  sx={{
                    display: "flex",
                    borderTop: "1px solid white",
                    width: "100%",
                  }}
                ></Box>
              </Grid>
              <Grid item md={3}>
                <Typography
                  sx={{ fontFamily: "Quicksand-Bold", fontSize: "16px" }}
                >
                  Teradomus 2025
                  <Link
                    href="https://www.instagram.com/teradomus/"
                    underline="none"
                    color="inherit"
                  >
                    <img
                      src={ins}
                      alt="instagram"
                      style={{
                        width: "60px",
                        height: "60px",
                        verticalAlign: "middle",
                        marginRight: -13,
                      }}
                    />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/teradomus/"
                    underline="none"
                    color="inherit"
                  >
                    <img
                      src={lin}
                      alt="linkedin"
                      style={{
                        width: "60px",
                        height: "60px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      );
    } else {
      return (
        <Box sx={{ backgroundColor: "#4669E8", mt: 7 }}>
          <Container>
            <Grid
              container
              sx={{ pt: 2, pb: 2, textAlign: "left", color: "white" }}
            >
              <Grid item xs={7}>
                <Link href="https://teradomus.com">
                  <Box
                    component="img"
                    alt="logo teradomus"
                    sx={{
                      width: "90%",
                    }}
                    src={logo}
                  />
                </Link>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Bold",
                    fontSize: "16px",
                    pt: 1,
                    lineHeight: 1.2,
                  }}
                >
                  ¿Tienes dudas o necesitas ayuda para agendar?
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontFamily: "Quicksand-Light",
                    fontSize: "16px",
                    lineHeight: 1.2,
                  }}
                >
                  <Link href="tel:56996906773" underline="none" color="inherit">
                    Llámanos por teléfono
                  </Link>
                  <br></br>
                  <Link
                    href="https://api.whatsapp.com/send/?phone=%2B56996906773&text=Hola,%20amigos%20de%20Teradomus.%20Quiero%20agendar%20una%20cita%20con%20un%20profesional%20de%20la%20salud.%20¿Podrían%20ayudarme%20😊?&type=phone_number&app_absent=0"
                    underline="none"
                    color="inherit"
                  >
                    Escríbenos al WhatsApp<br></br> +56 9 9690 6773
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Zap}
                    alt="Sello de Empresa"
                    style={{ width: "auto", height: "60px" }}
                  />
                  <img
                    src={Gob}
                    alt="Gobierno de Chile"
                    style={{ width: "auto", height: "80px" }}
                  />
                  <img
                    src={Mer}
                    alt="Mercado Libre"
                    style={{ width: "auto", height: "80px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={7} sx={{ ml: 2 }}>
                <Typography
                  sx={{ fontFamily: "Quicksand-Light", fontSize: "16px" }}
                >
                  <b>Legal</b>
                </Typography>
                <Link
                  href="https://teradomus-storage-public.s3.amazonaws.com/NO_BORRAR/CONDICIONES_CLIENTES.pdf"
                  underline="none"
                  color="inherit"
                >
                  <Typography
                    mt={3}
                    sx={{ fontFamily: "Quicksand-Light", fontSize: "15px" }}
                  >
                    Términos y condiciones<br></br>Políticas de privacidad
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontFamily: "Quicksand-Light", fontSize: "16px" }}
                >
                  <b>Recursos</b>
                </Typography>
                <Typography
                  mt={3}
                  sx={{ fontFamily: "Quicksand-Light", fontSize: "15px" }}
                >
                  <Link href="/blog" underline="none" color="inherit">
                    Blog
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={7} sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Light",
                    fontSize: "18px",
                    pt: 3,
                    color: "#4669E8",
                  }}
                >
                  <b>Acerca de</b>
                </Typography>
                <Link
                  href="https://teradomus.com/pro"
                  underline="none"
                  color="inherit"
                >
                  <Typography
                    mt={3}
                    sx={{ fontFamily: "Quicksand-Light", fontSize: "17px" }}
                  >
                    Atiende con Teradomus
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    borderTop: "1px solid white",
                    width: "110%",
                    mt: 5,
                    mb: 1,
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontFamily: "Quicksand-Bold", fontSize: "18px" }}
                >
                  Teradomus 2025
                  <Link
                    href="https://www.instagram.com/teradomus/"
                    underline="none"
                    color="inherit"
                  >
                    <img
                      src={ins}
                      alt="instagram"
                      style={{
                        width: "60px",
                        height: "60px",
                        verticalAlign: "middle",
                        marginRight: -13,
                      }}
                    />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/teradomus/"
                    underline="none"
                    color="inherit"
                  >
                    <img
                      src={lin}
                      alt="linkedin"
                      style={{
                        width: "60px",
                        height: "60px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      );
    }
  }

  return <>{Antes(isMobile)}</>;
}
