import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import callApi from "../../services/callApi";
import Loader from "../../components/Loader/Loader";
import logoImage from "../../assets/logo.png";

const ContactInfo = ({ userData, onNext, onBack }) => {
  const [telefono, setTelefono] = useState(userData?.telefono || "+56 ");
  const [email, setEmail] = useState(userData?.email || "");
  const [password, setPassword] = useState(userData?.password || "");
  const [confirmPassword, setConfirmPassword] = useState(
    userData?.password || ""
  );
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleTelefonoChange = (e) => {
    const prefix = "+56 ";
    let value = e.target.value;

    if (value.length < prefix.length) {
      setTelefono(prefix);
      return;
    }

    let numbers = value.replace(/\D/g, "");

    if (numbers.startsWith("56")) {
      numbers = numbers.substring(2);
    }

    numbers = numbers.substring(0, 9);

    let formatted = prefix;
    if (numbers.length > 0) {
      formatted += numbers[0];
    }
    if (numbers.length > 1) {
      formatted += " " + numbers.substring(1, 5);
    }
    if (numbers.length > 5) {
      formatted += " " + numbers.substring(5, 9);
    }

    setTelefono(formatted);
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isTelefonoValid = (telefono) => {
    const cleanNumber = telefono.replace(/\D/g, "");
    return cleanNumber.length === 11 && cleanNumber.startsWith("569");
  };

  const isPasswordValid = (password) => {
    return password.length >= 8;
  };

  const isFormValid = () => {
    return (
      isTelefonoValid(telefono) &&
      isEmailValid(email) &&
      isPasswordValid(password) &&
      password === confirmPassword
    );
  };

  // En el componente ContactInfo
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!telefono || !email || !password || !confirmPassword) {
      setError("Por favor, completa todos los campos");
      return;
    }
    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const formattedTelefono = "+" + telefono.replace(/\D/g, "");

      const response = await callApi("/registro/enviarCodigo", "post", {
        email,
        telefono: formattedTelefono,
        password,
        rut: userData.rut,
        nombres: userData.nombres,
        apellidos: userData.apellidos,
        profesion: userData.profesion,
      });

      if (response?.statusCode === 200) {
        onNext({
          ...userData,
          telefono: formattedTelefono,
          email,
          password,
          rut: userData.rut,
        });
      } else {
        // Mostrar el mensaje de error que viene en la respuesta
        setError("El correo ya está registrado en el sistema");
      }
    } catch (error) {
      console.error("Error al enviar código:", error);
      // Mostrar el mensaje de error específico del servidor
      setError("El correo ya está registrado en el sistema");
    } finally {
      setIsLoading(false);
    }
  };
  const inputSx = {
    "& .MuiInputBase-root": {
      height: 40,
      backgroundColor: "#f5f8fa",
      fontFamily: "Quicksand-Regular",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#dfe3e8",
      },
      "&:hover fieldset": {
        borderColor: "#b0b8c4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1877f2",
      },
    },
    "& .MuiInputBase-input": {
      padding: "8px 14px",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 9px) scale(1)",
      fontFamily: "Quicksand-Regular",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputLabel-asterisk": {
      display: "none",
    },
  };

  useEffect(() => {
    if (userData) {
      if (userData.telefono) setTelefono(userData.telefono);
      if (userData.email) setEmail(userData.email);
      if (userData.password) {
        setPassword(userData.password);
        setConfirmPassword(userData.password);
      }
    }
  }, [userData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          p: 3,
          borderRadius: 2,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          bgcolor: "background.paper",
        }}
      >
        <Loader isLoading={isLoading} />
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={logoImage} alt="Teradomus" style={{ height: "40px" }} />
        </Box>

        <Typography
          variant="h6"
          sx={{
            color: "#1877f2",
            mb: 1,
            fontSize: "20px",
            fontFamily: "Quicksand-Bold",
          }}
        >
          Hola {userData?.nombres} 👋
        </Typography>

        <Divider sx={{ width: "100%", mb: 2 }} />

        <Typography
          variant="body2"
          sx={{
            color: "#65676b",
            mb: 2,
            fontSize: "14px",
            fontFamily: "Quicksand-Regular",
          }}
        >
          Necesitamos tus datos de contacto y una contraseña para crear tu
          cuenta
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Celular"
            value={telefono}
            onChange={handleTelefonoChange}
            margin="normal"
            required
            placeholder="+56 9 xxxx xxxx"
            sx={inputSx}
            error={telefono !== "+56 " && !isTelefonoValid(telefono)}
            helperText={
              telefono !== "+56 " && !isTelefonoValid(telefono)
                ? "Ingresa un número válido (+56 9 xxxx xxxx)"
                : ""
            }
          />
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            sx={inputSx}
            error={email !== "" && !isEmailValid(email)}
            helperText={
              email !== "" && !isEmailValid(email)
                ? "Ingresa un email válido"
                : ""
            }
          />
          <TextField
            fullWidth
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
            sx={inputSx}
            error={password !== "" && !isPasswordValid(password)}
            helperText={
              password !== "" && !isPasswordValid(password)
                ? "La contraseña debe tener al menos 8 caracteres"
                : ""
            }
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Confirmar contraseña"
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
            required
            sx={inputSx}
            error={confirmPassword !== "" && password !== confirmPassword}
            helperText={
              confirmPassword !== "" && password !== confirmPassword
                ? "Las contraseñas no coinciden"
                : ""
            }
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isFormValid()}
            sx={{
              mt: 3,
              bgcolor: "#1877f2",
              "&:hover": { bgcolor: "#166fe5" },
              textTransform: "none",
              fontFamily: "Quicksand-Bold",
              height: 40,
              "&.Mui-disabled": {
                bgcolor: "rgba(0, 0, 0, 0.12)",
                color: "rgba(0, 0, 0, 0.26)",
              },
            }}
          >
            Continuar
          </Button>

          {error && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "14px",
                fontFamily: "Quicksand-Regular",
                textAlign: "center",
                mt: 2,
                p: 1,
                bgcolor: "rgba(211, 47, 47, 0.1)",
                borderRadius: 1,
              }}
            >
              {error}
            </Typography>
          )}
        </form>
        <Button
          variant="text"
          sx={{
            color: "#1877f2",
            textTransform: "none",
            fontFamily: "Quicksand-Regular",
            fontSize: "14px",
            mt: 1,
          }}
          onClick={onBack}
        >
          volver
        </Button>
      </Box>
    </Box>
  );
};

export default ContactInfo;
