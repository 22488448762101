import React, { useState } from "react";
import { Box, TextField, Button, Typography, Divider } from "@mui/material";
import callApi from "../../services/callApi";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Loader from "../../components/Loader/Loader";
import logoImage from "../../assets/logo.png";

const RutValidation = ({ onSuccess = () => {} }) => {
  const [rut, setRut] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const formatRut = (value) => {
    let cleanRut = value.replace(/[^0-9kK]+/g, "").toUpperCase();

    if (cleanRut.length > 1) {
      const body = cleanRut.slice(0, -1);
      const dv = cleanRut.slice(-1);
      const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      cleanRut = formattedBody + "-" + dv;
    }

    return cleanRut;
  };

  const isRutValid = (rut) => {
    const cleanRut = rut.replace(/[^0-9kK]/g, "");
    return cleanRut.length >= 3;
  };

  const handleRutChange = (e) => {
    const formattedRut = formatRut(e.target.value);
    setRut(formattedRut);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowError(false);

    try {
      const response = await callApi(
        `/registro/validarProfesional?rut=${rut}`,
        "get"
      );

      if (response?.datos_profesional) {
        onSuccess({
          ...response,
          rut: rut,
          datos_profesional: {
            ...response.datos_profesional,
            rut: rut,
          },
        });
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <ScrollToTop />
      <Loader isLoading={isLoading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: 400,
          padding: 3,
          borderRadius: 4,
          boxShadow:
            "0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 12px 24px rgba(0, 0, 0, 0.15)",
          bgcolor: "background.paper",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <img src={logoImage} alt="Teradomus" style={{ height: "40px" }} />
        </Box>

        <Typography
          variant="h6"
          sx={{
            color: "#1877f2",
            fontSize: "20px",
            fontFamily: "Quicksand-Bold",
            mb: 1,
            alignSelf: "flex-start",
          }}
        >
          Estamos felices verte aquí 🤩
        </Typography>
        <Divider sx={{ width: "100%", mb: 2 }} />

        <Typography
          variant="body2"
          sx={{
            mb: 2,
            color: "#65676b",
            fontSize: "14px",
            fontFamily: "Quicksand-Regular",
            alignSelf: "flex-start",
          }}
        >
          Necesitamos tu información personal para validar que eres un
          profesional de la salud y así darte acceso al ecosistema de Teradomus
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="rut"
            label="RUT"
            name="rut"
            autoComplete="off"
            autoFocus
            value={rut}
            onChange={handleRutChange}
            placeholder="XX.XXX.XXX-X"
            sx={{
              mb: 1,
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: "#f5f8fa",
                borderRadius: 1,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#dfe3e8",
                },
                "&:hover fieldset": {
                  borderColor: "#b0b8c4",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#1877f2",
                },
              },
              "& .MuiInputBase-input": {
                padding: "8px 14px",
              },
              "& .MuiInputLabel-root": {
                transform: "translate(14px, 9px) scale(1)", // Adjust the Y value (-8px) to move label up/down
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)", // Maintain position when shrunk
              },
              "& .MuiInputLabel-asterisk": {
                display: "none",
              },
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isRutValid(rut)}
            sx={{
              mt: 2,
              bgcolor: "#1877f2",
              "&:hover": { bgcolor: "#166fe5" },
              "&.Mui-disabled": {
                bgcolor: "rgba(24, 119, 242, 0.5)",
                color: "white",
              },
              textTransform: "none",
              fontFamily: "Quicksand-Bold",
              height: 40,
            }}
          >
            Continuar
          </Button>

          {showError && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "14px",
                fontFamily: "Quicksand-Regular",
                textAlign: "center",
                mt: 2,
                p: 1,
                bgcolor: "rgba(211, 47, 47, 0.1)",
                borderRadius: 1,
              }}
            >
              RUT no se encuentra en el registro de prestadores individuales de
              salud
            </Typography>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Button
              href="https://pro.teradomus.com"
              target="_blank"
              variant="text"
              sx={{
                color: "#1877f2",
                textTransform: "none",
                fontFamily: "Quicksand-Bold",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              Iniciar sesión
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RutValidation;
