import React from "react";
import { Typography, IconButton, Box, Button } from "@mui/material";
import { Grid } from "@mui/material";
import { Link } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";

import logo from "../../assets/logo.png";

const StartButton = styled(Button)(() => ({
  backgroundColor: "#4669E8",
  color: "white",
  padding: "0px 16px",
  borderRadius: "24px",
  textTransform: "none",
  fontFamily: "Quicksand-Bold",
  "&:hover": {
    backgroundColor: "#2952CC",
  },
}));

export default function MenuProfesiones() {
  const isDesktop = !isMobile;

  return (
    <>
      <Grid container alignItems="center">
        <Grid item lg={2} md={2} xs={5}>
          <Box sx={{ mt: 2.2, mb: 1.3 }}>
            <Link href="/" underline="none" color="inherit">
              <img
                src={logo}
                alt="Logo teradomus"
                style={{
                  height: isDesktop ? "40px" : "30px",
                }}
              />
            </Link>
          </Box>
        </Grid>
        {isMobile ? (
          <Grid
            item
            xs={7}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon sx={{ color: "#aeaeae" }} />
            </IconButton>
          </Grid>
        ) : (
          <>
            <Grid item lg={6} md={4.5} xs={2} />
            <Grid item lg={2} md={2.5} xs={2.5}>
              <Link
                href="https://pro.teradomus.com"
                underline="none"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 2,
                    fontFamily: "Quicksand-Bold",
                    fontSize: { lg: "18px", md: "18px", xs: "16px" },
                    color: "#4669E8",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#2952CC",
                    },
                  }}
                >
                  Iniciar sesión
                </Typography>
              </Link>
            </Grid>
            <Grid item lg={2} md={2.5} xs={2.5}>
              <Box sx={{ mt: 2 }}>
                <Link
                  href="https://www.teradomus.com/pro/registro"
                  underline="none"
                  style={{ textDecoration: "none" }}
                >
                  <StartButton
                    variant="contained"
                    sx={{
                      fontSize: { lg: "18px", md: "18px", xs: "16px" },
                    }}
                  >
                    Empieza gratis
                  </StartButton>
                </Link>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
