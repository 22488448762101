import React, { useState } from "react";
import RutValidation from "./Registro";
import PersonalInfo from "./PersonalInfo";
import ContactInfo from "./ContactInfo";
import CodeVerification from "./CodeVerification";
import Success from "./Succes";
import { Helmet } from "react-helmet";

const RegistrationFlow = () => {
  const [step, setStep] = useState(1);
  const [professionalData, setProfessionalData] = useState(null);
  const [personalInfo, setPersonalInfo] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [rut, setRut] = useState(null);

  const handleRutSuccess = (data) => {
    setProfessionalData(data);
    setRut(data.rut || data.datos_profesional.rut);
    setStep(2);
  };

  const handlePersonalInfoSubmit = (info) => {
    setPersonalInfo({
      ...info,
      rut: rut,
    });
    setStep(3);
  };

  const handleContactInfoSubmit = async (info) => {
    setContactInfo({
      ...info,
      rut: rut,
    });
    setStep(4);
  };

  const handleCodeSuccess = () => {
    setStep(5);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <RutValidation onSuccess={handleRutSuccess} />;
      case 2:
        return (
          <PersonalInfo
            data={professionalData}
            onNext={handlePersonalInfoSubmit}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <ContactInfo
            userData={{
              ...personalInfo,
              ...contactInfo, // Agregar contactInfo para mantener los datos al volver
              rut: rut,
            }}
            onNext={handleContactInfoSubmit}
            onBack={handleBack}
          />
        );
      case 4:
        return (
          <CodeVerification
            userData={{
              ...personalInfo,
              ...contactInfo,
              rut: rut,
            }}
            onNext={handleCodeSuccess}
            onBack={handleBack}
          />
        );
      case 5:
        return <Success />;
      default:
        return <RutValidation onSuccess={handleRutSuccess} />;
    }
  };

  const pageTitle = "Teradomos";
  const pageDescription = "Crea tu cuenta con nosotros.";

  return (
    <div className="min-h-screen w-full relative">
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/blog/calefaccion"
        />
        <meta property="og:type" content="article" />
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/blog/calefaccion"
        />
      </Helmet>
      <div className="relative">{renderStep()}</div>
    </div>
  );
};

export default RegistrationFlow;
