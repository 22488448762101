import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Divider,
} from "@mui/material";
import logoImage from "../../assets/logo.png";

const PersonalInfo = ({ data, onNext, onBack }) => {
  const [nombres, setNombres] = useState(data?.datos_profesional.nombres || "");
  const [apellidos, setApellidos] = useState(
    data?.datos_profesional.apellidos || ""
  );
  const [profesion, setProfesion] = useState(
    data?.datos_profesional.titulo_profesional || ""
  );
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isNombresValid = nombres.length >= 2 && nombres.length <= 45;
    const isApellidosValid = apellidos.length >= 2 && apellidos.length <= 45;
    const isProfesionValid = profesion.length > 0;

    setIsFormValid(isNombresValid && isApellidosValid && isProfesionValid);
  }, [nombres, apellidos, profesion]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Por favor, completa todos los campos correctamente");
      return;
    }
    onNext({ nombres, apellidos, profesion });
  };

  const inputSx = {
    mb: 1,
    "& .MuiInputBase-root": {
      height: 40,
      backgroundColor: "#f5f8fa",
      fontFamily: "Quicksand-Regular",
      borderRadius: 1,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#dfe3e8",
      },
      "&:hover fieldset": {
        borderColor: "#b0b8c4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1877f2",
      },
    },
    "& .MuiInputBase-input": {
      padding: "8px 14px",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 9px) scale(1)",
      fontFamily: "Quicksand-Regular",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputLabel-asterisk": {
      display: "none",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          p: 3,
          borderRadius: 2,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={logoImage} alt="Teradomus" style={{ height: "40px" }} />
        </Box>

        <Typography
          variant="h6"
          sx={{
            color: "#1877f2",
            mb: 1,
            fontSize: "20px",
            fontFamily: "Quicksand-Bold",
          }}
        >
          Hola {nombres} 👋
        </Typography>

        <Divider sx={{ width: "100%", mb: 2 }} />

        <Typography
          variant="body2"
          sx={{
            color: "#65676b",
            mb: 2,
            fontSize: "14px",
            fontFamily: "Quicksand-Regular",
          }}
        >
          Encontramos estos datos sobre ti en la Superintendencia de Salud. Por
          favor, ayúdanos a validarlos.
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nombres"
            value={nombres}
            onChange={(e) => setNombres(e.target.value)}
            margin="normal"
            required
            inputProps={{ maxLength: 45 }}
            FormHelperTextProps={{
              sx: { fontFamily: "Quicksand-Regular" },
            }}
            sx={inputSx}
          />
          <TextField
            fullWidth
            label="Apellidos"
            value={apellidos}
            onChange={(e) => setApellidos(e.target.value)}
            margin="normal"
            required
            inputProps={{ maxLength: 45 }}
            FormHelperTextProps={{
              sx: { fontFamily: "Quicksand-Regular" },
            }}
            sx={inputSx}
          />
          <TextField
            fullWidth
            select
            label="Profesión principal"
            value={profesion}
            onChange={(e) => setProfesion(e.target.value)}
            margin="normal"
            required
            sx={{
              ...inputSx,
              "& .MuiSelect-select": {
                fontFamily: "Quicksand-Regular",
              },
            }}
          >
            {data?.titulos_disponibles &&
              data.titulos_disponibles.map((titulo) => (
                <MenuItem
                  key={titulo}
                  value={titulo}
                  sx={{
                    fontFamily: "Quicksand-Regular",
                  }}
                >
                  {titulo}
                </MenuItem>
              ))}
          </TextField>

          {error && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "14px",
                fontFamily: "Quicksand-Regular",
                textAlign: "center",
                mt: 2,
                p: 1,
                bgcolor: "rgba(211, 47, 47, 0.1)",
                borderRadius: 1,
              }}
            >
              {error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isFormValid}
            sx={{
              mt: 3,
              bgcolor: "#1877f2",
              "&:hover": { bgcolor: "#166fe5" },
              "&.Mui-disabled": {
                bgcolor: "rgba(24, 119, 242, 0.5)",
                color: "white",
              },
              textTransform: "none",
              fontFamily: "Quicksand-Bold",
              height: 40,
            }}
          >
            Continuar
          </Button>
        </form>
        <Button
          variant="text"
          sx={{
            color: "#1877f2",
            textTransform: "none",
            fontFamily: "Quicksand-Regular",
            fontSize: "14px",
            mt: 1,
          }}
          onClick={onBack}
        >
          volver
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
