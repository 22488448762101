// PatientCareFeatures.jsx
import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Importar las imágenes GIF
import imagen4 from "../../../assets/rocket.png";
import imagen5 from "../../../assets/calendar.png";
import imagen6 from "../../../assets/gear.png";

const FeatureCard = ({ title, description, icon, isMobile }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Centrado para ambas versiones
      textAlign: "center",
      width: isMobile ? "100%" : "30%",
      px: isMobile ? 0 : 0,
    }}
  >
    <Box
      sx={{
        mb: 2,
        width: "60px", // Aumentado un poco el tamaño del contenedor
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginX: "auto", // Asegura centrado horizontal
      }}
    >
      <img
        src={icon}
        alt={title}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          display: "block", // Ayuda al centrado
          margin: "0 auto", // Asegura centrado horizontal
        }}
      />
    </Box>
    <Typography
      sx={{
        fontFamily: "Quicksand-Bold",
        fontSize: isMobile ? "18px" : "20px",
        color: "#454545",
        mb: 1,
        width: "100%",
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{
        fontFamily: "Quicksand-Regular",
        fontSize: isMobile ? "14px" : "16px",
        color: "#666666",
        maxWidth: isMobile ? "280px" : "none",
        width: "100%",
      }}
    >
      {description}
    </Typography>
  </Box>
);

const MobileCarousel = ({ features }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {features.map((feature, index) => (
        <Box key={index} sx={{ p: 0 }}>
          <FeatureCard {...feature} isMobile={true} />
        </Box>
      ))}
    </Slider>
  );
};

const PatientCareFeatures = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const features = [
    {
      title: "Mejora tu presencia online",
      description:
        "Atrae nuevos pacientes y facilita a tus usuarios el proceso de agenda.",
      icon: imagen4,
    },
    {
      title: "¡Olvídate del caos!",
      description:
        "Controla y organiza tu agenda, adquiere una gestión profesional.",
      icon: imagen5,
    },
    {
      title: "Optimiza tu día",
      description:
        "Automatiza tareas repetitivas, contarás con recordatorios, pagos automáticos y más.",
      icon: imagen6,
    },
  ];

  return (
    <Box sx={{ mt: { xs: 15, md: 15 }, mb: 4 }}>
      <Box sx={{ width: isMobile ? "100%" : "70%", mb: 6 }}>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: isMobile ? "20px" : "30px",
            color: "#454545",
            mb: 2,
            textAlign: isMobile ? "center" : "left",
          }}
        >
          Brindar una buena atención es tu trabajo, déjanos el resto a nosotros.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Quicksand-Regular",
            fontSize: isMobile ? "16px" : "18px",
            color: "#666666",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          Somos tu copiloto administrativo
        </Typography>
      </Box>

      {isMobile ? (
        <MobileCarousel features={features} />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            mt: 4,
            gap: 8,
          }}
        >
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} isMobile={false} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PatientCareFeatures;
