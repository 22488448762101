import React, { useEffect, useState } from "react";
import {
  Typography,
  Switch,
  Button,
  Paper,
  Box,
  useMediaQuery,
  Fade,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { isMobile } from "react-device-detect";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: isMobile ? 18 : 30,
  borderRadius: theme.spacing(2),
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  textAlign: "center",
  width: isMobile ? "90%" : "480px",
  marginRight: 50,
  paddingRight: isMobile ? undefined : 0,
  paddingLeft: isMobile ? undefined : 0,
}));

const StartButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#4669E8",
  color: "white",
  padding: theme.spacing(1, 3),
  borderRadius: theme.spacing(3),
  textTransform: "none",
  fontFamily: "Quicksand-Bold",
  "&:hover": {
    backgroundColor: "#2952CC",
  },
}));

const ContentSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: 40,
}));

const FeatureList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

const Feature = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  fontFamily: "Quicksand-Regular",
  "&::before": {
    content: '"✓"',
    color: "#3366FF",
    fontWeight: "bold",
  },
}));

const GradientText = styled(Typography)(({ theme, colors }) => ({
  backgroundImage: `linear-gradient(45deg, ${colors.join(",")})`,
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  display: "inline-block",
  fontFamily: "Quicksand-Bold",
}));

const PricingSection = () => {
  const [isYearly, setIsYearly] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=56920809101&text=Hola%20equipo%20de%20Teradomus!%20%F0%9F%91%8B%20Me%20llam%C3%B3%20la%20atenci%C3%B3n%20su%20plataforma%20y%20quiero%20probar%20el%20primer%20mes%20gratis.%20%F0%9F%8E%89&type=phone_number&app_absent=1";

  const [gradientColors, setGradientColors] = useState([
    "#4a90e2",
    "#50e3c2",
    "#4a90e2",
  ]);

  useEffect(() => {
    setGradientColors(
      isYearly
        ? ["#9b59b6", "#e74c3c", "#9b59b6"]
        : ["#4a90e2", "#50e3c2", "#4a90e2"]
    );
  }, [isYearly]);

  const features = [
    "Sitio web con perfil profesional",
    "Agenda online 24/7",
    "Pagos online",
    "Notificaciones por WhasApp y email",
    "Cobros automáticos",
    "Creación de pacientes ilimitados",
    "Creación de sesiones ilimitados",
    "Ficha clínica según normativa",
    "Archivos ilimitados por paciente",
    "Creación de servicios ilimitados",
    "Configura tu agenda presencial",
    "Configura tu agenda a domicilio",
    "Configura tu agenda online",
    "Análisis y reporte financieros",
    "Análisis y reporte de ocupación",
    "Soporte directo en horario laboral",
  ];

  const handleStartClick = () => {
    window.location.href = "/pro/registro";
  };

  const PriceDisplay = ({ price, period }) => (
    <Fade in={true} timeout={500}>
      <Box textAlign="center" mb={4}>
        <GradientText
          variant="h2"
          component="span"
          colors={gradientColors}
          sx={{
            fontSize: isMobile ? "35px" : "48px",
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
          }}
        >
          ${(price / 1000).toFixed(3)}
        </GradientText>
        <Typography
          variant="h4"
          component="span"
          color="text.secondary"
          ml={1}
          sx={{
            fontFamily: "Quicksand-Regular",
            fontSize: isMobile ? "20px" : "30px",
          }}
        >
          /{period}
        </Typography>
      </Box>
    </Fade>
  );

  return (
    <>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        sx={{
          color: "#454545",
          fontSize: isMobile ? "20px" : "30px",
          fontFamily: "Quicksand-Bold",
          mb: 3,
        }}
      >
        Disfruta de todas las herramientas de Teradomus
      </Typography>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
        {isDesktop && (
          <>
            <StyledPaper>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#454545",
                      fontSize: "30px",
                      fontFamily: "Quicksand-Bold",
                    }}
                  >
                    Plan único
                  </Typography>
                  <Typography
                    variant="body1"
                    fontFamily="Quicksand-Regular"
                    sx={{ mt: 2 }}
                  >
                    Todas las funcionalidades a un único precio{" "}
                    {isYearly ? "anual" : "mensual"}
                  </Typography>
                </Box>

                <Box sx={{ mt: 9, mb: 9 }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      color={!isYearly ? "primary" : "text.secondary"}
                      sx={{
                        fontFamily: !isYearly
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: isMobile ? "14px" : "16px",
                      }}
                    >
                      Mensual
                    </Typography>
                    <Switch
                      checked={isYearly}
                      onChange={() => setIsYearly(!isYearly)}
                      color="secondary"
                      sx={{ mx: 2 }}
                    />
                    <Typography
                      color={isYearly ? "secondary" : "text.secondary"}
                      sx={{
                        fontFamily: isYearly
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: isMobile ? "14px" : "16px",
                      }}
                    >
                      Anual
                    </Typography>
                  </Box>

                  <PriceDisplay
                    price={isYearly ? 99000 : 9900}
                    period={isYearly ? "año" : "mes"}
                  />
                </Box>

                <Box>
                  <Typography variant="body2" fontFamily="Quicksand-Regular">
                    IVA incluido
                  </Typography>
                  <StartButton
                    component="a"
                    onClick={handleStartClick}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ¡Empieza gratis!
                  </StartButton>{" "}
                </Box>
              </Box>
            </StyledPaper>
            <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index} variant="body2">
                  {feature}
                </Feature>
              ))}
            </FeatureList>
          </>
        )}

        {!isDesktop && (
          <StyledPaper>
            <ContentSection>
              <Typography
                variant="h5"
                sx={{
                  color: "#454545",
                  fontSize: "20px",
                  fontFamily: "Quicksand-Bold",
                }}
              >
                Plan único
              </Typography>
              <Typography variant="body1" fontFamily="Quicksand-Regular">
                Todas las funcionalidades a un único precio{" "}
                {isYearly ? "anual" : "mensual"}
              </Typography>

              <Box display="flex" alignItems="center" gap={1}>
                <Typography
                  color={!isYearly ? "primary" : "text.secondary"}
                  sx={{
                    fontFamily: !isYearly
                      ? "Quicksand-Bold"
                      : "Quicksand-Regular",
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                >
                  Mensual
                </Typography>
                <Switch
                  checked={isYearly}
                  onChange={() => setIsYearly(!isYearly)}
                  color="secondary"
                  sx={{ mx: 2 }}
                />
                <Typography
                  color={isYearly ? "secondary" : "text.secondary"}
                  sx={{
                    fontFamily: isYearly
                      ? "Quicksand-Bold"
                      : "Quicksand-Regular",
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                >
                  Anual
                </Typography>
              </Box>

              <PriceDisplay
                price={isYearly ? 99000 : 9900}
                period={isYearly ? "año" : "mes"}
              />

              <Typography variant="body2" fontFamily="Quicksand-Regular">
                IVA incluido
              </Typography>

              <StartButton onClick={handleStartClick}>
                ¡Empieza gratis!
              </StartButton>
            </ContentSection>

            <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index} variant="body2">
                  {feature}
                </Feature>
              ))}
            </FeatureList>
          </StyledPaper>
        )}
      </Box>
    </>
  );
};

export default PricingSection;
