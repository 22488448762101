import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import logoImage from "../../assets/logo.png";

const Success = () => {
  const handleLogin = () => {
    window.location.href = "https://pro.teradomus.com";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          p: 3,
          borderRadius: 2,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={logoImage} alt="Teradomus" style={{ height: "40px" }} />
        </Box>

        <Typography
          variant="h6"
          sx={{
            color: "#1877f2",
            mb: 1,
            fontSize: "20px",
            fontFamily: "Quicksand-Bold",
          }}
        >
          Todo listo, ya creamos tu cuenta 🎉
        </Typography>
        <Divider sx={{ width: "100%", mb: 2 }} />

        <Typography
          variant="body2"
          sx={{
            color: "#65676b",
            mb: 2,
            fontSize: "14px",
            fontFamily: "Quicksand-Regular",
          }}
        >
          ¡Te damos la bienvenida! 🎉🪩 Te invitamos a iniciar sesión y explorar
          tu plataforma. Muy pronto te contactaremos por WhatsApp para brindarte
          una breve inducción inicial y resolver todas tus dudas.
        </Typography>

        <Button
          fullWidth
          variant="contained"
          onClick={handleLogin}
          sx={{
            mt: 2,
            bgcolor: "#1877f2",
            "&:hover": { bgcolor: "#166fe5" },
            textTransform: "none",
            fontFamily: "Quicksand-Bold",
            height: 40,
          }}
        >
          Iniciar sesión
        </Button>
      </Box>
    </Box>
  );
};

export default Success;
